<template>
  <div class="content">
    <el-upload
      class="avatar-uploader"
      action="http://14.29.162.130:6602/image/imageUpload"
      list-type="picture-card"
      :show-file-list="true"
      :file-list="imageUrl"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :on-preview="handlePictureCardPreview"
    >
      <div slot="default" class="upload">
        <img src="@/assets/images/upload.png" alt="" />
        <p>点击上传头像</p>
      </div>
      <div
        slot="file"
        slot-scope="{ file }"
        class="el-upload-list__item is-success"
      >
        <img :src="imageUrl[0] ? imageUrl[0].url : []" class="avatar" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <div slot="tip" class="upload-text">
        建议图片大小150×150px， 格式为 gif, jpg, jpeg, png， 文件小于2M。
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="imageUrl[0] ? imageUrl[0].url : []" alt="" />
    </el-dialog>
    <div class="data">
      <div>
        <p>
          登录账号：<span>{{ user.telNum | num }}</span>
        </p>
        <p>账号类型：<span>个人用户</span></p>
        <p
          :style="
            inputShow
              ? 'margin-bottom:6px;margin-top:-6px;line-height:32px'
              : ''
          "
        >
          真实姓名：<span v-show="!inputShow" style="margin-left: 8px">{{
            username === null && user.name === null ? "无" : username
          }}</span>
          <input
            ref="userName"
            type="text"
            v-model="username"
            v-show="inputShow"
            @blur="inputShow = false"
          /><a @click="getFocus">修改</a>
        </p>
        <p>证件信息：<a style="margin: 0" @click="FormShow">查看证件信息</a></p>
        <p>
          绑定手机号：<span>{{ user.telNum | num }}</span
          ><a href="#/PersonalCenter/UserData/PhoneNumber">更改绑定</a>
        </p>
        <p>
          登录密码：<span>********</span
          ><a href="#/PersonalCenter/UserData/ChangePassword">修改</a>
        </p>
      </div>
      <button @click="save">保存</button>
    </div>
    <transition name="el-fade-in-linear">
      <div class="CertificateForm" v-show="CertificateForm">
        <div class="box">
          <div class="title">
            <h3>证件信息</h3>
            <svg-icon
              icon-class="ic-x"
              @click="CertificateForm = false"
            ></svg-icon>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ImgCutter from "vue-img-cutter";
import { mapState } from "vuex";

export default {
  name: "data-page",
  components: {
    ImgCutter,
  },
  computed: {
    ...mapState("personalUser", ["userInfo"]),
  },

  data() {
    return {
      user: "", //用户信息
      UserIdInformation: "",
      imageUrl: [], // 头像地址
      username: null, // 用户名
      inputShow: false,
      CertificateForm: false,
      dialogVisible: false,
      disabled: false,
    };
  },
  watch: {
    username: {
      handler: function (val, oldVal) {
        if (val === "") {
          this.username = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  filters: {
    num(e) {
      // console.log(e);
      if (e != undefined) {
        return e.substr(0, 3) + "****" + e.substr(7);
      }
    },
  },
  created() {
    // console.log(this.$route.query.r);
    // if(this.$route.query.r){
    //   this.$router.push({
    //     path: "/PersonalCenter/UserData/DataPage",
    //   });
    // }
    this.setUserInfo();
  },

  methods: {
    setUserInfo() {
      if (sessionStorage.getItem("userInfo")) {
        let user = JSON.parse(sessionStorage.getItem("userInfo"));
        this.user = user.userEntity;
        this.UserIdInformation = user.userDetailEntity;
        this.username = this.user.name;
        this.UserIdInformation.profilePicture
          ? this.imageUrl.push({ url: this.UserIdInformation.profilePicture })
          : "";
      } else {
        setTimeout(() => {
          sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          this.user = this.userInfo.userEntity;
          this.UserIdInformation = this.userInfo.userDetailEntity;
          this.username = this.user.name;
          this.UserIdInformation.profilePicture
            ? this.imageUrl.push({ url: this.UserIdInformation.profilePicture })
            : "";
        }, 150);
      }
    },
    handleRemove(file) {
      this.imageUrl = [];
      // console.log(file);
    },
    handlePictureCardPreview(file) {
      console.log(file);
      // this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      if (res.code === "SUCCESS") {
        this.imageUrl.push({ url: res.data });
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (
        file.type != "image/png" &&
        file.type != "image/jpeg" &&
        file.type != "image/gif"
      ) {
        this.$message.error("上传头像图片只能是 gif, jpg, jpeg, png 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return false;
      }
    },

    getFocus() {
      this.inputShow = true;
      this.$nextTick(() => {
        this.$refs.userName.focus();
      });
    },
    save() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.updateUser();
      }, 200);
    },
    async updateUser() {
      
      //修改用户信息
      const data = await this.$API.personalUser
        .updateUser({
          object: {
            id: this.user.id,
            userDetailEntity: {
              // frontId: "",
              id: this.user.id,
              // idNumber: "",
              profilePicture:
                this.imageUrl.length === 0 ? null : this.imageUrl[0].url, //头像
              // reverseId: "",
              // userId: "",
            },
            userEntity: {
              actualName: "",
              id: this.user.id,
              name: this.username === "" ? null : this.username, //名称
              password: "",
              telNum: this.user.telNum,
              username: this.user.username,
            },
          },
        })
        .then((data) => {
          this.$message({
            message: "资料保存成功！",
            type: "success",
            offset: "450",
          });
          sessionStorage.removeItem("userInfo");
          this.$store.dispatch("personalUser/getUserInfo");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("更改失败");
        });
      setTimeout(() => {
        sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      }, 100);
    },
    FormShow() {
      this.CertificateForm = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  padding: 30px 24px;
  .avatar-uploader {
    .avatar {
      width: 150px;
      height: 150px;
      display: block;
    }
    .upload {
      width: 150px;
      height: 150px;
      background: rgba(250, 250, 250, 0.39);
      // border: 1px dashed #d9d9d9;
      opacity: 1;
      border-radius: 2px;
      img {
        width: 40px;
        margin-top: 43px;
      }
      p {
        position: absolute;
        font-size: 12px;
        line-height: 24px;
        color: #333333;
        top: 88px;
        left: 50%;
        margin-left: -36px;
        z-index: 0;
      }
    }
    .upload:hover {
      border-color: #2696ff;
    }
    .upload-text {
      margin-top: 8px;
      width: 153px;
      font-size: 12px;
      line-height: 16px;
      color: #999999;
      text-align: left;
    }
  }

  .data {
    margin-left: 30px;
    div {
      display: flex;
      flex-wrap: wrap;

      p {
        flex: 60%;
        font-size: 14px;
        color: #888888;
        line-height: 20px;
        margin-bottom: 16px;
        span {
          color: #333333;
        }
        a {
          color: #086ac2;
          cursor: pointer;
          margin-left: 14px;
        }
        input {
          width: 160px;
          height: 30px;
          border: 1px solid #95b9f3;
          opacity: 1;
          border-radius: 2px;
          padding: 0 8px;
        }
        input:focus {
          border: 1px solid var(--subjectColor);
          outline: none;
        }
      }
      p:nth-child(2n) {
        flex: 40%;
      }
    }
    button {
      margin-top: 12px;
      width: 68px;
      height: 30px;
      background: var(--subjectColor);
      opacity: 1;
      border-radius: 2px;
      cursor: pointer;
      outline: none;
      border: none;
      font-size: 14px;
      color: #ffffff;
    }
  }

  .CertificateForm {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.39);
    z-index: 2000;
    .box {
      width: 560px;
      height: 602px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -280px;
      margin-top: -301px;
      .title {
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        border-bottom: 1px solid #e8e8e8;
        h3 {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .svg-icon {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.avatar-uploader {
  position: relative;
}
.el-upload-list {
  position: absolute;
  top: 0;
  z-index: 1;

  li {
    transition: 0s;
    div {
      border: none !important;
    }
  }
  // li {
  //   margin-top: 0 !important;
  //   .el-upload-list__item {
  //     margin-top: 0 !important;
  //     position: relative;

  //     // position: absolute;
  //     // width: 100%;
  //     // height: 100%;
  //     // left: 0;
  //     // top: 0;
  //     // cursor: default;
  //     // text-align: center;
  //     // color: #fff;
  //     // opacity: 0;
  //     // font-size: 20px;
  //     // background-color: rgba(0, 0, 0, 0.5);
  //     // transition: opacity 0.3s;
  //   }
  // }
}
</style>